.innerBannerImg h2 {
  padding-top: 220px;
  color: #fff;
  text-align: center;
  font-size: 70px;
  font-weight: 600;
  text-shadow: 0px 0px 20px #000;
}

#ivaas-banner-title {
  font-size: 55px;
  background: -webkit-linear-gradient(#f6cea5, #ef5130, #ea484a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
}

.Gitbtn {
  position: absolute;
  right: 10%;
  bottom: 10%;
}

.Gitbtn a:first-child {
  margin-right: 25px;
}

.ccGegr {
  height: 50px;
  width: 180px;
  border-radius: 1vw !important;
  background-color: #fff !important;
  color: rgb(58, 58, 58) !important;
  font-size: 1.2vw !important;
  font-weight: 600;
  text-transform: uppercase;
}
@media (min-width: 320px) and (max-width: 567px) {
  .innerBannerImg h2 {
    font-size: 3em;
    padding-top: 140px;
  }

  .Gitbtn a:first-child {
    margin-right: 20px;
  }
}

@media (min-width: 20px) and (max-width: 400px) {
  .Gitbtn a:first-child {
    margin-right: 15px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .innerBannerImg h2 {
    font-size: 3em;
  }
  .Gitbtn {
    position: absolute;
    right: 0;
    bottom: 5%;
    left: 0;
    margin: 0 auto;
    text-align: center;
  }
  .Gitbtn a:first-child {
    margin-right: 15px;
  }

  .ccGegr {
    height: 35px;
    width: 120px;
    font-size: 13px !important;
  }
}

@media (min-width: 100px) and (max-width: 320px) {
  .innerBannerImg h2 {
    font-size: 35px;
  }
  .Gitbtn {
    position: absolute;
    right: 0;
    bottom: 5%;
    left: 0;
    margin: 0 auto;
    text-align: center;
  }
  .ccGegr {
    height: 25px;
    width: 90px;
    font-size: 10px !important;
  }
}
