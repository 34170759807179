/* rotating animation  */

header {
  z-index: 100;
  width: 100%;
  min-width: 4em;
  top: 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* border-bottoms-left-radius: 15px;
    border-bottom-right-radius: 15px; */
}

.sub-dropdown-menu {
  padding: 0 !important;
  border-radius: 5px;
}

.sub-dropdown-menu li a {
  background-color: transparent !important;
  color: #4d4643 !important;
  width: 250px;
  text-transform: uppercase;
  border: transparent !important;
}

.sub-dropdown-menu li a:hover {
  color: var(--theme-color) !important;
}
.menu li.active a {
  width: 250px;
  padding: 5px 10px;
  color: var(--border-line);
  font-weight: 500;
  border: 2px solid var(--border-line);
  border-radius: 15px !important;
}

.grad-theme-color {
  background-image: var(--grad-theme-color) !important;
}

.highlight-box {
  /* background-color: var(--theme-color); */
  background-image: var(--grad-theme-color);
  padding: 10px 20px !important;
  border-radius: 5px !important;
  color: #fff;
}

.highlight-box:hover,
.highlight-box:active,
.highlight-box:focus,
.highlight-box:visited {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  color: #fff !important;
}

/* #loading {
  animation: rotation 2s infinite linear;
  -webkit-animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    -webkit-transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(-359deg);
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(-359deg);
  }
} */
